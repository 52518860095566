import _ from "lodash";
import * as ChangeCase from "change-case";
import { ENV } from "../constants/Config";
import en from "translations/en/index";
import zhHantHK from "translations/zhHantHK/index";
import { Language } from "constants/General";
import Config from '../constants/Config'
const func = (props) => props;

const Helper = {
    AcceptLang: (language) => {
		const [defaultLang = "en"] = Config.ACCEPT_LANG.filter((lang) =>
			lang.includes(language)
		);
		return defaultLang;
	},
    /**
     * Sleep
     * @param {Number} timeout
     */
    Wait: (timeout) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, timeout);
        });
    },
    /**
     * 轉換blob到base64
     * @param {String} blob
     */
    BlobToBase64: (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    },
    /**
     * 清除符號
     * @param {String} str
     */
    ClearSpecialCharacter: (str = "") => {
        return str.replace(/[` ~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "");
    },

    /**
     * @param {string} title
     * @param {Array|Object|string} log
     */
    Print: (title, log = []) => {
        if (ENV.isDev) {
            const logs = _.isArray(log) ? log : [log];
            console.group(`${title}`);
            logs.forEach((el) => console.log(el));
            console.groupEnd();
            console.log("\n");
        }
    },

    /**
     * 更改Object propName. ex: 'camelCase', 'snakeCase', ...
     * @param {object} obj
     * @param {string} funcName - function name
     * @param {function} adapterFunc - (key, value) => newValue
     * @param {boolean} deepChange - 深層改變名稱
     * @returns {object}
     */
    ChangeCase: (data, funcName, adapterFunc, deepChange) => {
        // value 轉換器
        const adapter = _.isFunction(adapterFunc)
            ? adapterFunc
            : (key, value) => value;

        // 指定的case function
        const caseName = funcName || "camelCase";

        // 限定 array or object
        if (!_.isObject(data)) return data;

        if (_.isArray(data)) {
            return data.map((item) =>
                Helper.ChangeCase(item, caseName, adapter, deepChange)
            );
        }

        const newObj = {};
        // Loop Object
        Object.keys(data).forEach((key) => {
            // key 轉換case
            const propName = ChangeCase[caseName](key);
            const value = data[key];
            let processedValue = adapter(propName, value);
            // value轉換 fallback
            if (_.isNil(processedValue)) {
                processedValue = value;
            }
            newObj[propName] = deepChange
                ? Helper.ChangeCase(
                    processedValue,
                    caseName,
                    adapter,
                    deepChange
                )
                : processedValue;
        });

        return newObj;
    },

    NumberWithCommas: (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    NumberToDistount: (number) => {
        if (number % 10 === 0) {
            return number / 10;
        } else if (number < 10) {
            return number / 10;
        } else {
            return number;
        }
    },
    /**
     * 檢查副檔名
     * @param {String} extName 檔案的副檔名
     * @param {Array} validImageExt 接受的副檔名
     */
    CheckExtension: (extName, validImageExt) => {
        let foundExt = false;
        for (let ext in validImageExt) {
            if (extName.toLowerCase() === validImageExt[ext]) {
                foundExt = true;
                break;
            }
        }
        return foundExt;
    },

    /**
     * 取得相片長寬
     * @param {String} image
     */
    GetImageSide: (image) => {
        return new Promise(async (resolve, reject) => {
            try {
                var tempImg = new Image();
                tempImg.crossOrigin = true;
                tempImg.src = image;

                await tempImg.decode();
                console.log("Width: ", tempImg.width);
                console.log("Height: ", tempImg.height);
                const imageSize = {
                    width: tempImg.width,
                    height: tempImg.height,
                };
                resolve(imageSize);
            } catch (error) {
                console.log(error);
                reject(false);
            }
        });
    },
    /**
     * Language Getter
     * @param {String} image
     */
    GetTranlations: (lang, textId) => {
        switch (lang) {
            case Language.EN:
                return en[textId];
            case Language.TW:
                return zhHantHK[textId];
            default:
                return zhHantHK[textId];
        }
    },
    /**
     * 檢查副檔名
     * @param {String} src 照片的Src
     * @param {Function} callback 回調函數
     * @param {String} outputFormat 輸出的mimetype, 像image/jpeg
     */
    ToDataURL(src, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
            var canvas = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src =
                "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    },
};

export default Helper;

const palette = {
    primary: {
        thin: "#fcd2da",
        xLight: "#efb4c1",
        light: "#ffe1e7",
        main: "#ff84aa",
        dark: "#ff6696",
    },
    sub: {
        main: "#fff2cc",
    },
    secondary: {
        light: "#3FA5EC",
        main: "#147BC2",
        dark: "#0D5485",
    },
    light: {
        light: "#F9FBFF",
        main: "#D5DFF2",
        dark: "#B3C4E6",
    },
    dark: {
        light: "#6B84AE",
        main: "#3C4A62",
        dark: "#353541",
    },
    error: {
        light: "#FC8F8F",
        main: "#FC4343",
        dark: "#AE2E2E",
        mui: "#f44336",
    },
    background: {
        default: "transparent",
    },
    common: {
        black: "#000",
        white: "#FFF",
    },
    text: {
        primary: "#353541",
        secondary: "#85888C",
        disabled: "#A8AAAD",
        hint: "#00CB4D",
    },
    grey: {
        light: "#DCDDDE",
        main: "#85888C",
        dark: "#4A4D4F",
        50: "#F6F6F7",
        100: "#EDEEEE",
        200: "#DCDDDE",
        300: "#CBCCCE",
        400: "#B9BBBD",
        500: "#A8AAAD",
        600: "#96999C",
        700: "#85888C",
        800: "#74777B",
        900: "#636669",
        A100: "#4A4D4F",
        A200: "#3A3C3D",
        A400: "#292B2C",
        A700: "#191A1A",
    },
    action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    status: {
        online: "#4caf50",
        offline: "#9e9e9e",
        censoring: "#ffd600",
    },
    icon: {
        main: "rgba(117, 117, 117, 1)",
        grey: "#999999",
    },
};

export default palette;

import { FETCH_ACCOUNT, LOGOUT } from "actions/account";

const accountReducer = (state = {}, action) => {
	switch (action.type) {
		case FETCH_ACCOUNT: {
			if (!action.payload) return state;
			return action.payload;
		}

		case LOGOUT: {
			return {};
		}

		default:
			return state;
	}
};

export default accountReducer;

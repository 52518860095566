import Config from "constants/Config";
import _ from "lodash";
import Axios from "axios";
import Helper from "utils/Helper";


const RegionAPI = {
    GetUserRegion: async () => {
        try {
            const { data } = await Axios.get(Config.GEO_LOCATION_URL, { withCredentials: false });
            return data;
        } catch (error) {
            Helper.Print("Error: GET UserRegion Failed", error);
        }
    },

};

export default RegionAPI;

import RegionAPI from 'services/RegionAPI.js';
import LSKey from "constants/LocalStorageKey";
import _ from 'lodash';

export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_SITE = "CHANGE_SITE";
export const SET_REGION = "SET_REGION";

export const changeLang = lang => ({
    type: CHANGE_LANG,
    payload: lang,

})

export const setRegion = () => async (dispatch) => {
    let lang = localStorage.getItem(LSKey.Language);
    const info = await RegionAPI.GetUserRegion();

    const acceptLang = ["en", "zh-Hant-HK"];
    if (_.isNull(lang)) {
        const [defaultLang = "zh-Hant-HK"] = acceptLang.filter(lang =>
            lang.includes(info.language)
        );
        lang = defaultLang;
    }

    dispatch({
        type: SET_REGION,
        payload: { info, lang },
    })

}

// in dashboard or in market
export const changeSite = (site) => ({
    type: CHANGE_SITE,
    payload: site,
});

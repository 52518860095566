import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import Helper from "utils/Helper";
import Translations from "translations";

if (!Intl.getCanonicalLocales) {
    require("@formatjs/intl-getcanonicallocales/polyfill");
}

if (!Intl.Locale) {
    require("@formatjs/intl-locale/polyfill");
}

if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill");
}

if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill");
}

const mapStateToProps = (state) => {
    const { general = {} } = state;
    const { lang } = general;

    const cleanLang = Helper.ClearSpecialCharacter(lang);
    const messages = Translations[cleanLang] || Translations.zhHantHK;

    return { locale: lang, messages };
};

export default connect(mapStateToProps)(IntlProvider);

import React, { useEffect, Suspense, lazy } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ToastContainer } from "react-toastify";
import { GAInit, GAPageView } from "utils/GA";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

// Components
import Redirect from "components/Redirect";
const Login = lazy(() => import("components/login/Login"));
const Register = lazy(() => import("components/register/Register"));
const Reset = lazy(() => import("components/reset/Reset"));
const Navigation = lazy(() => import("components/navigation/Navigation"));

const useStyles = makeStyles((theme) => ({
    toastBar: {
        zIndex: 9999,
    },
}));

const App = () => {
    const classes = useStyles();
    const theme = useTheme();
    const screenDownSM = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        GAInit("UA-113783728-5");
        GAPageView();
    }, []);

    return (
        <div
            className="container-fluid"
            style={{
                width: "100vw",
                minHeight: "100vh",
                padding: 0,
                overflow: "hidden",
                backgroundColor: "#F9FBFF",
            }}
        >
            <>
                <Suspense fallback={<div />}>
                    <Switch>
                        {/* <Route exact path="/" component={Redirect} /> */}
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/reset" component={Reset} />
                        <Route path="/" component={Navigation} />
                    </Switch>
                </Suspense>
            </>

            <ToastContainer
                className={classes.toastBar}
                position={screenDownSM ? "top-left" : "bottom-right"}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default App;

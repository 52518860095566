import React from "react";
/**
 * 重新導向
 */
function Redirect() {
	window.location.href = `${window.origin}/login`;

	return <div className="h-100 w-100 position-absolute" />;
}

export default Redirect;

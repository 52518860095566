import LSKey from "constants/LocalStorageKey";
import { CHANGE_LANG, CHANGE_SITE, SET_REGION } from "actions/general";
import { DashboardPaths, MarketPaths, Sites } from "constants/General";
import { lang } from "moment";

// Languages
const storedLang = localStorage.getItem(LSKey.Language);



let initialSite = Sites.NotFound;
let inDashboard = DashboardPaths.hasOwnProperty(window.location.pathname);
let inMarket = MarketPaths.hasOwnProperty(window.location.pathname);
if (inDashboard) {
  initialSite = Sites.Dashboard;
} else if (inMarket) {
  initialSite = Sites.Market;
}

const defaultLang = "zh-Hant-HK";
const defaultState = {
  lang: storedLang || defaultLang,
  profile: {},
  region: {},
  site: initialSite
};
const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_LANG: {
      localStorage.setItem(LSKey.Language, action.payload);

      return {
        ...state,
        lang: action.payload
      };
    }
    case CHANGE_SITE: {
      return {
        ...state,
        site: action.payload
      };
    }
    case SET_REGION: {
      return {
        ...state,
        region: action.payload.info,
        lang: action.payload.lang,
      };
    }
    default: {
      return state;
    }
  }
};

export default generalReducer;

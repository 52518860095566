/* eslint-disable no-template-curly-in-string */
const en = {
    "account.addInfo": "Add info",
    "account.bannerTitle": "Banner Title",
    "account.basicInfo": "Basic Info",
    "account.brandInfo": "Brand Info",
    "account.brandName": "Brand Name",
    "account.changeInfo": "Modify Info",
    "account.changePassword": "Change Password",
    "account.description": "Description",
    "account.detail": "Bank Detail",
    "account.documentImage": "ID Photo",
    "account.email.goValid": "Validate Email",
    "account.email.isValid": "Validated",
    "account.error.avatarInvalid":
        "The size of the avatar must be smaller than 300x300",
    "account.error.backgroundInvalid":
        "The size of the background must be 1080x1920",
    "account.error.backgroundOverSize":
        "The file size of image is over 5MB, please upload another one",
    "account.error.getBackground": "Failed to get background",
    "account.error.getBanners": "Failed to get banners",
    "account.error.getImages": "Failed to get images",
    "account.error.imageOverSize":
        "The file size of image is over 2MB, please upload another one",
    "account.error.removeBackground": "Remove failed",
    "account.error.uploadImage": "Upload failed",
    "account.idCardBack": "ID Card's Back",
    "account.idCardFront": "ID Card's Front",
    "account.link": "Brand Link",
    "account.linkName": "Brand Link Name:",
    "account.machineBackground": "Machine Background",
    "account.noLink": "Link not set",
    "account.notFilled": "Empty",
    "account.pageTitle": "Memopresso - Account",
    "account.passbook": "Passbook",
    "account.removeMachineBackground": "Remove",
    "account.setStoryUrl": "Set Story",
    "account.storyUrl": "IG Story Link:",
    "account.success.removeBackground": "Remove successfully",
    "account.updateAvatar":
        "▲ Click above to update avatar (Square Image, size lower than 300)",
    "account.uploadBanner": "▲ Click above to upload image",
    "account.uploadDetailImage": "▲ Click above to select image (2MB)",
    "account.uploadMachineBackground":
        "▲ Click above to upload image (Width:1080, Height:1920)",
    "account.constant.address": "Address",
    "account.constant.bank": "Bank",
    "account.constant.bankAccount": "Bank Account",
    "account.constant.bankBranch": "Bank Branch",
    "account.constant.currentAddress": "Current Address",
    "account.constant.foreign183less":
        "Not Taiwanese, and not live in Taiwan over 183 days",
    "account.constant.foreign183more":
        "Not Taiwanese, but live in Taiwan over 183 days",
    "account.constant.idNumber": "Id Number",
    "account.constant.nationality": "Nationality",
    "account.constant.native": "Native",
    "account.constant.phone": "Phone Number",
    "account.constant.realName": "Real Name",
    "account.changePlan": "Change Plan",
    "account.currentPlan": "Current Plan:",
    "account.plan": "Co-op Plan",

    "albumMenu.albumNotCreated": "No Album found",
    "albumMenu.edit": "Edit album",
    "banner.image": 'Banner image ({width}x{height})',
    "banner.cleanSelection": "Clean selection",
    "banner.error.extInValid": "This type of file is not supported",
    "banner.error.needTwoType": "Please upload both size of the banners",
    "banner.error.overSize":
        "The file size of image is over 5MB, please upload another one",
    "banner.error.sizeNotValid": "The image size is not valid",
    "banner.upload": "Upload",
    "banner.uploadHintP1":
        "Click here to select an image (jpg or png, within 5MB,",
    "banner.uploadHintP2": "The width and height of the image must be",
    "banner.view": "View",

    "cashflow.amount": "Amount",
    "cashflow.balance": "Balance",
    "cashflow.bank": "Bank",
    "cashflow.bankAccount": "Bank Account",
    "cashflow.button.withdrawApplying": "Withdraw Applying",
    "cashflow.date": "Date",
    "cashflow.error.cancel": "You cannot cancel the completed application",
    "cashflow.error.withdrawApplied":
        "Your have applied the withdrawal, please wait until that withdrawal is completed",
    "cashflow.error.withdrawApply":
        "Failed to send withdrawal application, please try again later",
    "cashflow.error.withdrawNumber":
        "The amount of withdrawal cannot less than $1000NTD or larger than",
    "cashflow.expose": "Total Impressions",
    "cashflow.fee": "Fee",
    "cashflow.hint.applyRequirement":
        "To withdraw, you need to have balance over $1000, an validated email and fill all bank information",
    "cashflow.hint.applying":
        "Please wait until the previous order is completed",
    "cashflow.info": "Cashflow Info",
    "cashflow.monthlyExpose": "Monthly Impressions",
    "cashflow.monthlyRevenue": "Monthly Revenue",
    "cashflow.previousMonthExpose": "Last Month Impressions",
    "cashflow.previousMonthRevenue": "Last Month Revenue",
    "cashflow.status": "Status",
    "cashflow.success.withdrawApply": "Apply withdrawal successfully",
    "cashflow.summary": "Summary",
    "cashflow.title": "Memopresso - Cashflow",
    "cashflow.withdrawAmount": "Withdrawal amount",
    "cashflow.withdrawApply": "Withdrawal apply",
    "cashflow.withdrawInfo":
        "The minimal amount of withdrawal is $1000NTD, \band $30 fee will be charged. We will process your request soon after you apply",
    "cashflow.withdrawRecord": "Withdrawal",

    "changePassword.changePassword": "Change Password",
    "changePassword.error.password": "Wrong Password",
    "changePassword.hint.inputNew": "Input new password",
    "changePassword.hint.inputNewAgain": "Input new password again",
    "changePassword.hint.inputOld": "Input previous password",
    "changePassword.success.change": "Change password successfully",

    "frame.album.create": "Create Album",
    "frame.manage": "Manage Album",
    "frame.album.placeholder": "My Album",
    "frame.error.notCensored": "The chosen frame is not censored yet",
    "frame.error.notFound": "No changeable frame",
    "frame.error.sameStatus": "The chosen frame is already on the status",
    "frame.error.updateStatus": "Failed to update status",
    "frame.hint.createNewFrame": "Click add button to upload new frame!",
    "frame.pageTitle": "Memopresso - Frame",
    "frame.success.updateStatus": "Successfully update status",
    "frame.selectBackFrame": "Select Background Frame",
    "frame.selectLayer": "Select Top Layer Frame",
    "frame.frameSetting": "Frame Setting",

    "frame.basicInfo": "Basic Info",
    "frame.albumName": "Album Name",
    "frame.backFrame": "Background Frame",
    "frame.layerOptional": "Layer Frame(Optional)",
    "frame.album.uploadLayerHintP1": `Click here to select an image (png, within 5MB,`,
    "frame.finishedMessage":
        "Successfully create a new frame. Currently, the new frame is offline, you can set it online after it is censored by our staffs",

    "frame.error.fileSize":
        "The file size of image is over 5MB, please upload another one",
    "frame.error.sizeNotTheSame":
        "The size of the background and the layer must be the same",
    "frame.error.emptyBackFrame": "Background Frame cannot be empty",
    "frame.error.merge": "Merging the background and the layer failed",
    "frame.error.album":
        "Album error occurred, please try again later or contact customer service",

    "frame.albumEdit.title": "Edit Album",
    "frame.album.background": "Background(Optional)",
    "frame.album.uploadHintP1":
        "Click here to select an image (jpg or png, within 5MB,",
    "frame.album.uploadHintP2": "The width and height of the image must be ",
    "frame.hintAdImage": "Printing page image(Optional)",
    "frame.albumEdit.uploadAdHint": `Click here to select a file (jpg, png, gif, mp4, within 5MB,`,
    "frame.detail.title": "Frame Detail",
    "frame.detail.preview": "Preview:",
    "frame.detail.backFrame": "Background Frame:",
    "frame.detail.layer": "Layer Frame:",
    "frame.album.filter": "Filter",
    "frame.album.moveTo": "Move to",
    "frame.selectAll": "Select All",
    "frame.downloadMultiple": "Download Selected",
    "frame.censorMultiple": "Censor Selected",
    "frame.onlineMultiple": "Online Selected",
    "frame.offlineMultiple": "Offline Selected",
    "frame.deleteMultiple": "Delete Selected",
    "frame.editTag": "Edit tag",
    "frame.download": "Download",
    "frame.censorOne": "Censor",
    "frame.onlineOne": "Set Online",
    "frame.offlineOne": "Set Offline",
    "frame.deleteOne": "Delete",
    "frame.add": "Create Frame",
    "frame.showDemo": "Show Template Image",
    "frame.censorReject": "Reject",

    "faq.pageTitle": "Memopresso - FAQ",
    "faq.header": "How can we help you?",

    "tagEdit.title": "Tag Edit",
    "tag.multiple": "A frame cannot have the same tag",
    "tag.saveError": "Save tag failed",
    "tagEdit.info.maxTagNumberP1": "Up to ",
    "tagEdit.info.maxTagNumberP2": " tags",
    "tagEdit.tagWithInP1": "(Within ",
    "tagEdit.tagWithInP2": " words)",
    "tagEdit.error.maxTagNumberP1": "A frame can only have less than ",
    "tagEdit.error.maxTagNumberP2": " tags",
    "tagEdit.add": "Add tag",

    "global.add": "Add",
    "global.album.add": "Add Album",
    "global.album.manage": "Manage Album",
    "global.album.switch": "Switch Album",
    "global.album.edit": "Edit Album",
    "global.apply": "Apply",
    "global.censor": "Censor",
    "global.change": "Change",
    "global.create": "Create",
    "global.cleanSelection": "Clean",
    "global.delete": "Delete",
    "global.error.emailFormat": "Wrong Email format",
    "global.error.happened": "Error happened",
    "global.error.loadData": "Failed to load data",
    "global.error.login":
        "Failed to login, please try again later or contact custom service",
    "global.error.normal":
        "Error happened, please try again later or contact custom service",
    "global.error.notPermitted": "Permission denied",
    "global.error.operate":
        "Opearation failed, please try again later or contact custom service",
    "global.error.passwordFormat": "Wrong password format",
    "global.error.pwNotTheSame": "Passwords are not the same",
    "global.error.selectFile": "Failed to select file",
    "global.error.system": "System Error",
    "global.error.modify":"Modify Faild",
    "global.hint.inputPassword": "Input password",
    "global.hint.inputPasswordAgain": "Input password again",
    "global.hint.passwordFormat":
        "Password is a combination of 6 to 20 characters and digits",
    "global.noData": "No data",
    "global.noTag": "No tag",
    "global.offline": "Offline",
    "global.online": "Online",
    "global.printed": "Printed",
    "global.reset": "Reset",
    "global.select": "Select",
    "global.selectAll": "Select All",
    "global.send": "Send",
    "global.refresh": "refresh",
    "global.success.delete": "Delete Successfully",
    "global.success.modify": "Modify Successfully",
    "global.success.validate": "Validate Successfully",
    "global.unselectAll": "Unselect All",
    "global.upload": "Upload",
    "global.validate": "Validate",
    "global.cancelUpload": "Cancel upload",
    "global.cancelUpload.warning":
        "Image is not uploaded yet. If you close this window, the progress will not be saved. Do you want to close?",
    "global.continueUpload": "Continue",
    "global.cancel": "Cancel",
    "global.complete": "Complete",
    "global.error": "Error",
    "global.error.system.normal": "System error, please try again",
    "global.error.system.refresh": "System error, please refresh page",
    "global.noValue": "No value",
    "global.processing": "Processing",
    "global.preview": "Preview",
    "global.update": "update",
    "global.reselect": "Reselect",
    "global.price": "Price",
    "global.priceDescription": "The price user will see is: ",
    "global.error.imgSizeNotValid": "The image size is not valid",
    "global.error.extInValid": "This type of file is not supported",
    "global.close": "Close",
    "global.back": "Back",
    "global.error.emptyPrice": "Price cannot be empty",
    "global.error.priceFormat": "Price format is not correct",
    "global.error.negativePrice": "Price cannot be negative",
    "global.error.minimalPrice": "Price cannot be lower than 25",
    "global.cleanAll": "Clean All",
    "global.save": "Save",
    "global.pixel": " pixel",
    "global.error.specialSymbol": "Cannot contain special characters",
    "global.setting": "Setting",
    "global.edit": "Edit",
    "global.next": "Next",
    "global.error.add":
        "Add failed, please try again later or contact customer service",
    "global.success.add": "Add successfully",
    "global.emptyResult": "Result not found",
    "global.searching": "Searching",
    "albumswitch.hint.name": "Input Illustrator Name",
    "global.deleteConfirmTitle": "Delete Confirm?",
    "global.deleteConfirm":
        "This operation cannot be recovered, do you want to delete it?",
    "global.confirm": "Confirm",
    "global.error.priceNotAMutiple": "Price can only be the multiple of 5",
    "global.input.empty": "Please input {text}",
    "global.input.invalid": "Please input vaild {text}",
    "guide.pageTitle": "Memopresso - Guide",
    "guide.header": "上架方式",
    "guide.subHeader": "從註冊到上架販售！",
    "guide.step1Title": "創作者註冊",
    "guide.step1Text": "輸入Email並設定密碼，商家連結名稱請以英文設定。",
    "guide.step2Title": "創建相框相簿",
    "guide.step2Text1": "a.  進入後點選建立相簿即會跳出以下視窗",
    "guide.step2Text2":
        "b.  輸入相簿名稱。（此名稱將作為日後用戶搜尋您的關鍵字，後續無法更改）",
    "guide.step2Text3": "c.  上傳相簿圖檔。（可讓用戶更清楚找到您的商店）",
    "guide.step3Title": "上傳相框",
    "guide.step3Text1": "a.  按下“新增”即可上傳相框圖檔",
    "guide.step3Text2": "b.  依指示操作就完成囉！(單層相框上傳步驟一即可)",
    "guide.step4Title": "等待審核",
    "guide.step4Text": "上傳完成後，將針對是否符合審核準則進行審核。",
    "guide.step5Title": "商店開張！",
    "guide.step5Text1":
        "a. 當您看到相框狀態為“未上線”，表示已通過審核，後續您可自行調整成“上線中”",
    "guide.step5Text2": "b. 可新增標籤增加搜尋時的曝光度。",
    "guide.step6Title": "提領收入",
    "guide.step6Text1": "收入將依照您的定價進行分潤。",
    "guide.coop.title": "分潤合作方案",
    "guide.comission.online.title": "線上商店分潤",
    "guide.step6Text2": "＊定價分潤計算方式：",
    "guide.comissionOnlineText1": "兩張50元至100元：提供您20%（含稅）拆潤",
    "guide.comissionOnlineText2": "兩張125元至300元：提供您30%（含稅）拆潤",
    "guide.comissionOnlineText3": "兩張325元至450元：提供您40%（含稅）拆潤",
    "guide.comissionOnlineText4": "兩張475元（含）以上：提供您50%（含稅）拆潤",
    "guide.comissionOnlineText5": "＊為避免價格過於繁雜，定價需以25元為單位",
    "guide.comissionOnlineText6":
        "＊此分潤僅適用線上商店之銷售額，因定價彈性，實體機台尚未配合",
    "guide.comission.offline.title": "實體機台合作方案",
    "guide.comissionOfflineText1": "以下方案三選一：",
    "guide.comissionOfflineText2":
        "（方案為期三個月，欲更改方案可在三個月後於creators studio 更改）",
    "guide.comissionOfflineText3":
        "Ａ：每個創作相框提供額外10組序號讓您與粉絲互動",
    "guide.comissionOfflineText4":
        "Ｂ：機台首頁、等待列印頁面行銷曝光（各一張）",
    "guide.comissionOfflineText5": "Ｃ：機台銷售額提供5%（含稅）拆潤",
    "guide.comissionOfflineText6": "＊實體機台販售價格均為兩張50元",
    "guide.comission.comment":
        "您可以獲得線上商店分潤外加一種實體機台合作方案。",

    "home.pageTitle": "Memopresso Creator Market",
    "home.introTitleLine1": "設計你獨一無二的相框",
    "home.introTitleLine2": "保存你所有美好的回憶！",
    "home.joinButton": "立即開始",
    "home.point1.title": "免費上架，讓您盡情揮灑創意。",
    "home.point1.description": "免註冊費、免上架費，人人都可以是創作者！",
    "home.point1.link": "逛逛插畫家相框市集",
    "home.point2.title": "彈性定價，自由販售您設計的相框。",
    "home.point2.description": `相框依照不同販售價格可獲得不同的分潤比例，自由尋找最合適的價格吧！`,
    "home.point2.link": "了解詳細定價與分潤方式",
    "home.point3.title": "後台輕鬆查看相框成效，隨時追蹤數據。",
    "home.point3.description": "隨時可以查看您的曝光率與收益，資訊不漏接！",
    "home.point3.link": "註冊我的商店",
    "home.point4.title":
        "讓朋友、另一半都來使用您的相框保存彼此之間的重要回憶吧！",
    "home.point4.description": "了解設計規範讓您節省時間快速上架！",
    "home.point4.link": "查看相框製作規範",
    "home.bottomTitleLine1": "不知從哪著手嗎？別擔心，",
    "home.bottomTitleLine2": "先來逛逛超過百位插畫家的相框市集吧！",
    "home.goMarket": "相框市集GO!",

    "image.album.placeHolder": "My images",
    "image.edit.height": "Current height:",
    "image.edit.minimal": " (Min: ",
    "image.edit.rotateRight90": "Rotate right 90 degree",
    "image.edit.toHorizontal": "Convert to horizontal",
    "image.edit.toVertical": "Convert to vertical",
    "image.edit.width": "Current width:",
    "image.edit.zoom": "Zoom",
    "image.error.notCensored": "Please wait until the image is censored",
    "image.error.notFound": "No changeable image found",
    "image.error.sameStatus": "The image is already on the status ",
    "image.error.updateStatus": "Failed to update status",
    "image.hint.createNewImage": "Click add button to upload new image!",
    "image.pageTitle": "Memopresso - Image",
    "image.success.updateStatus": "Update status successfully",

    "image.select.image": "Select Image",
    "image.select.video": "Select Video(Optional)",
    "image.fillData": "Image Setting",
    "image.video.calculating":
        "Optional - Video Setting(Calculating Confidence... Please Wait)",
    "image.video.lowConfidence":
        "Optional - Video Setting(Sorry, this image may not be able to detected. Please change another one)",
    "image.video.setting": "Optional - Video Setting",
    "image.error.sizeOver":
        "The file size of the image is over 10MB, please upload another one",
    "image.error.extInValid": "Currently, only jpg image is supported",
    "image.error.sizeInValid":
        "The shortest side of the image must be larger than ",
    "image.info.autoAdjustSize":
        "The size of the image have been adjusted automatically, if you want to proceed high resolution image, please use computer to upload",
    "image.error.select": "Select image failed",
    "image.error.videoOverSize":
        "The file size of the video is over 20MB, please upload another one",
    "image.error.videoExtInValid": "This video format is not supported",
    "image.error.uploadVideo": "Upload video failed",
    "image.error.emptyName": "Image name cannot be empty",
    "image.error.emptyImage": "Image cannot be empty",
    "image.error.lowConfidenceUpload":
        "Sorry, this image may not be able to detected, please upload another one",
    "image.error.videoExtNotSupport": "This video extension is not supported",
    "image.imageNotSelected": "You have to select an image first",
    "image.error.videoLength": "Video length cannot over 30 seconds",
    "image.error.videoRatio":
        "The size ratio of the video should be similar to the image",
    "image.add": "Add",
    "image.image": "Image",
    "image.hint.upload": `Click to upload image(jpg,
        Within 10MB)`,
    "image.hint.cropDone": "Done",
    "image.hint.reCrop": "Crop",
    "image.detail": "Image Detail",
    "image.detail.name": "Name:",
    "image.detail.price": "Price:",
    "image.detail.status": "Status:",
    "global.contactCustomService":
        "(If there is any question, please contact customer service)",
    "image.detail.clickToPreview": "(Click to Preview)",
    "image.detail.arDeviceHint": `(Iphone user please use Safari to open,
        Android user please use Chrome)`,
    "image.detail.uploadTime": "Upload time:",
    "image.detail.videoPreview": "Video preview",

    "image.expand.filter": "Filter",
    "image.expand.moveTo": "Move to",

    "image.selectAll": "Select All",
    "image.downloadMultiple": "Download Selected",
    "image.censorMultiple": "Censor Selected",
    "image.onlineMultiple": "Online Selected",
    "image.offlineMultiple": "Offline Selected",
    "image.deleteMultiple": "Delete Selected",
    "image.editTag": "Edit tag",
    "image.download": "Download",
    "image.censorOne": "Censor",
    "image.onlineOne": "Set Online",
    "image.offlineOne": "Set Offline",
    "image.deleteOne": "Delete",
    "image.hint.selectVideo": `Click to select a video shorter than 30 sec(Video length must be similar to the image,
        Within 20MB)`,
    "image.name": `Image Name`,
    "image.success.add": "Successfully add new image!",
    "image.finishedMessage":
        "It would take about 10 minutes to prepare you video, this dialog can be closed at anytime. You can check the video status in the image detail.",

    "login.error": "Wrong Email or password",
    "login.error.emptyEmail": "Please input your email",
    "login.error.password": "Please input your password",
    "login.forgetPassword": "Forgot password?",
    "login.forgotPasswordMessage":
        "\bThe reset password link will be sent to your registerd Email",
    "login.forgotPasswordTitle": "Forgot password",
    "login.register": "Register for free!",
    "login.serviceTerm": "Memopresso service term",
    "login.success": "Login successfully",
    "login.success.resetLinkSent": "Reset password link is sent",

    "manage.cashflow.amount": "Amount",
    "manage.cashflow.applyDate": "Apply date",
    "manage.cashflow.balance": "Balance",
    "manage.cashflow.bank": "Bank",
    "manage.cashflow.bankAccount": "Bank Account",
    "manage.cashflow.completeTime": "Complete Time",
    "manage.cashflow.error.update":
        "Error happened, please check whether the balance is enough",
    "manage.cashflow.fee": "Fee",
    "manage.cashflow.status": "Status",
    "manage.cashflow.title": "Withdrawal Process",
    "manage.delivery.address": "Address",
    "manage.delivery.copyAddress": "Address copied",
    "manage.delivery.copyAddressHint": "Click to copy address",
    "manage.delivery.copyRecipient": "Recipient Copied",
    "manage.delivery.copyRecipientHint": "Click to copy recipient",
    "manage.delivery.createDate": "Create Date",
    "manage.delivery.deliveryTime": "Delivery Time",
    "manage.delivery.mailingNumber": "Mailing Number",
    "manage.delivery.mobile": "Mobile",
    "manage.delivery.orderId": "Order Id",
    "manage.delivery.payment": "Payment",
    "manage.delivery.price": "Price",
    "manage.delivery.quantity": "Quantity",
    "manage.delivery.recipient": "Recipient",
    "manage.delivery.serialcode": "Serial Code",
    "manage.delivery.shippingfee": "Shipping fee",
    "manage.delivery.status": "Order Status",
    "manage.delivery.remarks": "Order Remarks",
    "manage.delivery.title": "Delivery Info",
    "manage.delivery.user": "User Id",
    "manage.coupon.name": "Event Name",
    "manage.coupon.newCustomer": "new customer",
    "manage.coupon.tags": "Event Tag",
    "manage.coupon.quantity": "Quantity",
    "manage.coupon.count": "Used Count",
    "manage.coupon.uselimit": "Max Quantity",
    "manage.coupon.channel": "Channel",
    "manage.coupon.startDate": "Start Date",
    "manage.coupon.endDate": "End Date",
    "manage.coupon.type": "Type",
    "manage.coupon.discount": "Discount/PointBack",
    "manage.coupon.minimum": "Minimum",
    "manage.coupon.maximum": "Max Discount",
    "manage.coupon.serialNumber": "Serial Number",
    "manage.coupon.creator": "Creator",
    "manage.coupon.updatedTime": "Updated Time",
    "manage.coupon.title": "Coupon Manage",
    "manage.coupon.success.add": "Coupon Add Successful",
    "manage.coupon.error.add": "Coupon Add Faild",
    "manage.coupon.success.update": "Coupon Update Successful",
    "manage.coupon.error.update": "Coupon Update Faild",
    "manage.homepage.banner.eventName":"Event Name",
    "manage.delivery.select": "{0} orders selected",
    "manage.delivery.download.xslx": "Download as xslx",
    "manage.delivery.download.pdf": "Download as PDF",
    "manage.homepage.banner.eventDate": "Event Date Range",
    "manage.hompage.banner.del.confirm": "Are you sure you want to delete ?",
    'manage.hompage.category.eventName.duplicate':'Event Name ㄎuplicate',
    "manage.hompage.category.update": "Update Category",
    "manage.hompage.category.frame.weekHot": 'Week Hot',
    "manage.hompage.category.frame.monthHot": 'Month Hot',
    "manage.hompage.category.frame.recommend": 'Recommend',
    "manage.hompage.category.frame.new": 'New',
    "manage.hompage.category.frame.other": 'Other',
    "manage.hompage.category.product.mask": 'Mask Container',
    "manage.hompage.category.product.frame": 'Frame',
    "manage.hompage.category.zh": 'Chinese',
    "manage.hompage.category.en": 'English',
    "manage.hompage.category.th": 'Thai',
    "manage.hompage.category.ko": 'Korean',
    "manage.hompage.category.image": 'Category Image({width}x{height})',
    "manage.hompage.category.tag": 'Frame tag',
    "manage.hompage.category.tagNotFound": 'Not Found Tags',
    "manage.hompage.category.tag.invalid": 'Please input vaild tag',
    "manage.hompage.category.tag.duplicate":"Tag duplicate",
    "manual.pageTitle": "Memopresso - Manual",
    "manual.faceFrame": "人像相框",
    "manual.imageFrame": "圖像相框",
    "manual.requiredAmount": "所需數量",
    "manual.requiredPixel": "圖片大小(Pixel)",
    "manual.frameDesignLayer": "* 相框設計層",
    "manual.albumFile": "相簿圖檔",
    "manual.machineHomePage": "* 機台首頁設計圖",
    "manual.waitingPrintPage": "* 等待列印頁面",
    "manual.header": "設計規範",
    "manual.subHeader": "您需要準備…",
    "manual.frameTableHeader": "相框檔案",
    "manual.machineTableHeader": "機台合作檔案",
    "manual.comment1": "·　有＊符號的為必要準備項目",
    "manual.comment2": "相簿圖檔：",
    "manual.comment3":
        "可想像成作品集封面，用戶在選相框時，會先看到相簿圖，裡面才是您的所有相框。",
    "manual.comment4": "機台首頁設計圖：",
    "manual.comment5": "設計圖會於全台實體機台首頁輪播曝光。",
    "manual.comment6": "等待列印頁面：",
    "manual.comment7": "等待相片列印時約需15~30秒，用戶等待列印時可再次曝光。",
    "manual.frameExampleHeader": "相框範例",

    "manual.frameDesignLayerNumber": "單層1張或雙層2張",
    "manual.frameDesignConcept": "相框設計概念",
    "manual.frameSingleDesignConcept": "單層設計：底色層 x 1",
    "manual.frameDoubleDesignConcept": "雙層設計：設計層x 1、底色層 x 1",
    "manual.singleLayer": "單層相框",
    "manual.doubleLayer": "雙層相框",
    "manual.albumScreen": "相簿圖檔呈現畫面",
    "manual.printingPage": "等待列印廣告頁面",
    "manual.machineHomePageTitle": "機台首頁",
    "manual.preserveSpace": "預留邊界",
    "manual.frameSpecLine1": "含出血相框大小：897 x 1205 px",
    "manual.frameSpecLine2": "完稿尺寸（紅線內）：881 x 1177px",
    "manual.frameSpecLine3": "左右上方各出血 8px",
    "manual.frameSpecLine4": "下方出血 20px",
    "manual.frameDesignExampleHeader": "設計題材參考",
    "manual.celebrationDayFrame": "節日相框",
    "manual.themeFrame": "主題相框",
    "manual.aiDownload": "尺寸ai檔下載",
    "manual.frameAndAlbumAI": "相框＆相簿圖ai檔",
    "manual.machineHomePageAndPrintPageAI": "機台首頁設計＆列印頁面ai檔",
    "manual.designRule": "違規條例",
    "manual.designRuleTitle": "若相框含有以下內容，",
    "manual.designRuleTitleEmphasis": "審核將不予通過",
    "manual.designRule1":
        "違反善良風俗、教唆未成年者飲酒吸菸、猥褻、暴力、煽動民族主義的內容",
    "manual.designRulecLine2":
        "上傳過於裸露、煽情、挑逗之個人照片，包含以馬賽克或塗白等方式遮掩重要部位皆屬違規",
    "manual.designRulecLine3": "上傳明星、其他KOL等非本人之照片相框",
    "manual.designRulecLine4": "抄襲、盜用其他插畫家之設計相框",
    "manual.designRulecLine5":
        "過度商業化之相框內容，如：於相框、名稱內置入公告或企業商標",
    "manual.face.comment6": "·　目前人像相框不支援實體機台上架。",
    "manual.face.comment4": "人像相框：",
    "manual.face.comment5":
        "以照片攝影方式所呈現出的人物肖像，若是經過繪圖、顏繪、Ｑ版畫風等以插畫方式呈現者則可歸類於圖像相框。",
    "manual.tutorialTitle": "沒想法嗎？這裡提供懶人包讓您快速上手！",
    "manual.threeStepTutorial": "超簡單三步驟相框教學",
    "manual.albumScreenMobile": "在手機上",
    "manual.albumScreenMachine": "在機台上",

    "navigation.account": "Account Info",
    "navigation.admin": "Admin",
    "navigation.cashflow": "Cashflow",
    "navigation.cashflowManage": "Withdrawal Process",
    "navigation.dashboard": "Login",
    "navigation.delivery": "Delivery",
    "navigation.faq": "FAQ",
    "navigation.frame": "Frame",
    "navigation.market": "Market",
    "navigation.manual": "Manual",
    "navigation.guide": "Guide",
    "navigation.home": "Home",
    "navigation.image": "Image",
    "navigation.login": "Login",
    "navigation.logout": "Logout",
    "navigation.logoutFailed": "Logout failed",
    "navigation.logoutSucceed": "Logout successfully",
    "navigation.frameManage": "Manage Frame",
    "navigation.coupon": "Coupon",
    "navigation.homepage": "Homepage",
    "product.status.censoring": "Censoring",
    "product.status.deleted": "Deleted",
    "product.status.none": "None",
    "product.status.offline": "Offline",
    "product.status.online": "Online",
    "product.status.processing": "Processing",
    "product.status.ready": "Ready",
    "product.status.waiting": "Waiting",
    "product.status.rejected": "Rejected",

    "register.agreeTerm": "Agree the term",
    "register.alreadyHasAccount": "Already has an account?",
    "register.error.emailUsed": "Email is used",
    "register.error.linkNameFormat": "Wrong link name format",
    "register.goLogin": "Go to Login",
    "register.help.linkName":
        "Link Name is a combination of 6 to 20 characters and digits",
    "register.hint.linkName": "Link Name",
    "register.register": "Register",
    "register.success.login":
        "Register successfully, redirecting to login page",
    "register.term": "Service Term",
    "register.title": "Signup",

    "reset.goBackToLogin": "Go to Login",
    "reset.linkOutDated": "Link outdated",
    "reset.pageTitle": "Memopresso - Reset Password",
    "reset.password.reset": "Reset Password",
    "reset.success.updatePW": "Reset successfully",

    "updateAccount.brandName": "Brand Name",
    "updateAccount.description": "Description",
    "updateAccount.error.empty": "The field cannot be empty",
    "updateAccount.error.linkFormat": "Wrong link name format",
    "updateAccount.error.linkUsed": "Link name has been used",
    "updateAccount.error.update": "Failed to update detail",
    "updateAccount.fbUrl": "Facebook Link",
    "updateAccount.igUrl": "Instagram Link",
    "updateAccount.linkName": "Link Name",
    "updateAccount.linkNameEn": "Link Name",
    "updateAccount.success.update": "Update Successfully",
    "updateAccount.update": "Update Detail",
    "updateDetail.error.empty": "Please fill all fields",
    "updateDetail.error.update": "Update detail failed",
    "updateDetail.hint.address": "Address",
    "updateDetail.hint.bank": "Select a bank",
    "updateDetail.hint.bankBranch": "Select a bank branch",
    "updateDetail.hint.name": "Full Name",
    "updateDetail.hint.nationality": "Nationality",
    "updateDetail.hint.same": "Same as above",
    "updateDetail.success.update": "Update bank info successfully",
    "updateDetail.update": "Update",
    "updateStory.error.update": "Update story failed",
    "updateStory.error.urlFormat": "Wrong link format",
    "updateStory.hint.url": "Story link",
    "updateStory.success.update": "Update successfully",
    "updateStory.update": "Update Story Link",

    "verifyEmail.email": "Validate Email",
    "verifyEmail.emailResent": "Validation email has been resent",
    "verifyEmail.error.number": "Wrong validation code",
    "verifyEmail.error.numberFormat": "The code is 6 digits",
    "verifyEmail.hint.emailNotReceived":
        "Validation email has been sent, not received?",
    "verifyEmail.hint.number": "Input 6 digits",
    "verifyEmail.sendAgain": "Send again",
    "verifyEmail.success": "Email validate successfully",

    "notfound.pageNotFound": "Sorry, page not found",

    //footer
    "footer.about": "About Memopresso",
    "footer.intro":
        "Through distinguished social media and the integration of software and hardware, we adopt unmanned retail technology and furthermore cooperate with illustrators and creators, providing young users with a solution to quickly save, create and share personalized photos as well as creating an apparent social media sharing experience.",
    "footer.guideTitle": "Link",
    "footer.guide.about": "About",
    "footer.guide.use": "How to use",
    "footer.guide.location": "Machine Loc",
    "footer.guide.hotCreator": "Hot Creator",
    "footer.guide.business": "Business",
    "footer.guide.lang": "Language",
    "footer.contact.tel": "Tel: 02-37655060",
    "footer.contact.mail": "Email: business@memopresso.com",
    "footer.guide.link": "Creators",
    "footer.guide.termAndPolicy": "Terms and Policy",
    "footer.guide.policy": "Privacy",
    "footer.guide.censor": "Censoring",

    "searchbox.placeholder": "Please type your question"
};

export default en;

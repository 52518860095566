import Config from "constants/Config";
import _ from "lodash";
import Axios from "axios";
import Helper from "utils/Helper";
import Moment from "moment";

Axios.defaults.withCredentials = true;

const AccountAPI = {
    Fetch: async () => {
        try {
            const { data = {} } = await Axios.get(
                `${Config.BASE_URL}/account/fetch`
            );
            return {
                ...data,
                avatar: `${data.avatar}?timestamp=${Moment().unix()}`,
            };
        } catch (error) {
            Helper.Print("Error: Fetch Failed", error);
        }
    },
    ChangePwd: async (oldPassword, newPassword) => {
        try {
            const { data = {} } = await Axios.post(
                `${Config.BASE_URL}/account/changePwd`,
                {
                    oldPassword,
                    newPassword,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: ChangePwd Failed", error);
        }
    },
    Login: async (dataSet = {}) => {
        try {
            const { data = {} } = await Axios.post(
                `${Config.BASE_URL}/account/login`,
                dataSet
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Login Failed", error);
            return false;
        }
    },
    Logout: async () => {
        try {
            const result = await Axios.post(
                `${Config.BASE_URL}/account/logout`
            );
            return result;
        } catch (error) {
            Helper.Print("Error: Logout Failed", error);
        }
    },
    UploadAvatar: async (imageData) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/uploadAvatar`,
                {
                    data: imageData,
                }
            );
            return data.url;
        } catch (error) {
            Helper.Print("Error: UploadAvatar Failed", error);
        }
    },
    Update: async (dataSet = {}) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/update`,
                {
                    dataSet,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Update Account Failed", error);
        }
    },
    Register: async (dataSet = {}) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/register`,
                {
                    dataSet,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Register Account Failed", error);
        }
    },
    GenVerifyNumber: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/genVerifyNumber`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Gen Verify Number Failed", error);
        }
    },
    GenVerifyNumberInRegister: async (email) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/genVerifyNumberInRegister`,
                {
                    email,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Gen Verify Number Failed", error);
        }
    },
    VerifyEmail: async (number = "") => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/verifyEmail`,
                {
                    number,
                }
            );
            return data.result;
        } catch (error) {
            Helper.Print("Error: Verify Number Failed", error);
        }
    },
    VerifyEmailInRegister: async (email = "", number = "") => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/verifyEmailInRegister`,
                {
                    email,
                    number,
                }
            );
            return data.result;
        } catch (error) {
            Helper.Print("Error: Verify Number Failed", error);
        }
    },
    SendResetLink: async (email = "") => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/sendResetLink`,
                {
                    email,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Send Reset Link Failed", error);
        }
    },
    CheckResetLink: async (email = "", number = "") => {
        try {
            const {
                data: { isValid = false },
            } = await Axios.post(`${Config.BASE_URL}/account/checkResetLink`, {
                email,
                number,
            });
            return isValid;
        } catch (error) {
            Helper.Print("Error: Send Reset Link Failed", error);
        }
    },
    ResetPwd: async (email = "", key = "", password = "") => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/resetPwd`,
                {
                    email,
                    key,
                    password,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Reset Password Failed", error);
        }
    },
    GetImages: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/getImages`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Get Images Failed", error);
        }
    },
    UploadImage: async (imageData, type) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/uploadImage`,
                {
                    data: imageData,
                    type,
                }
            );
            return data.url;
        } catch (error) {
            Helper.Print("Error: UploadImage Failed", error);
        }
    },
    UploadBanner: async (largeBanner, smallBanner) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/uploadBanner`,
                {
                    largeBanner,
                    smallBanner,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: UploadBanner Failed", error);
        }
    },
    GetBanners: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/getBanners`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: Get Banners Failed", error);
        }
    },
    RemoveBanner: async (bannerId) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/removeBanner`,
                {
                    bannerId,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: RemoveBanner Failed", error);
        }
    },
    UploadBackground: async (background) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/uploadBackground`,
                {
                    background,
                }
            );

            return data;
        } catch (error) {
            Helper.Print("Error: UploadBackground Failed", error);
        }
    },
    GetBackground: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/getBackground`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: GetBackground Failed", error);
        }
    },
    RemoveBackground: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/removeBackground`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: RemoveBackground Failed", error);
        }
    },
    GetPlans: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/getPlans`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: GetPlans Failed", error);
        }
    },
    GetAccountPlan: async () => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/getAccountPlan`
            );
            return data;
        } catch (error) {
            Helper.Print("Error: GetAccountPlan Failed", error);
        }
    },
    SetAccountPlan: async (planId) => {
        try {
            const { data } = await Axios.post(
                `${Config.BASE_URL}/account/setAccountPlan`,
                {
                    planId,
                }
            );
            return data;
        } catch (error) {
            Helper.Print("Error: SetAccountPlan Failed", error);
        }
    },
};

export default AccountAPI;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountReducer from "reducers/account";
import generalReducer from "reducers/general";

const createRootReducer = (history, action) =>
    combineReducers({
        router: connectRouter(history, action),
        account: accountReducer,
        general: generalReducer,
    });

export default createRootReducer;

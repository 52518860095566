/* eslint-disable no-template-curly-in-string */
const zh = {
    "account.addInfo": "新增資訊",
    "account.bannerTitle": "橫幅圖片",
    "account.basicInfo": "基本資訊",
    "account.brandInfo": "品牌資訊",
    "account.brandName": "商家名稱:",
    "account.changeInfo": "變更資訊",
    "account.changePassword": "變更密碼",
    "account.description": "描述:",
    "account.detail": "收款資訊",
    "account.documentImage": "證件照片",
    "account.email.goValid": "驗證信箱",
    "account.email.isValid": "已驗證",
    "account.error.avatarInvalid": "相片的寬高必須小於300x300",
    "account.error.backgroundInvalid": "背景的寬高必須是1080x1920",
    "account.error.backgroundOverSize": "照片尺寸超過5MB，請重新上傳",
    "account.error.getBackground": "取得Background失敗",
    "account.error.getBanners": "取得Banner失敗",
    "account.error.getImages": "取得圖片失敗",
    "account.error.imageOverSize": "照片尺寸超過2MB，請重新上傳",
    "account.error.removeBackground": "刪除失敗",
    "account.error.uploadImage": "上傳圖片失敗",
    "account.idCardBack": "身分證反面",
    "account.idCardFront": "身分證正面",
    "account.link": "商家連結:",
    "account.linkName": "商家連結名稱(英文):",
    "account.machineBackground": "機器背景圖",
    "account.noLink": "尚未設定連結名稱",
    "account.notFilled": "未填寫",
    "account.pageTitle": "Memopresso - 帳戶資訊",
    "account.passbook": "存摺封面",
    "account.removeMachineBackground": "清除背景",
    "account.setStoryUrl": "設定網址",
    "account.storyUrl": "IG Story網址:",
    "account.success.removeBackground": "刪除成功",
    "account.updateAvatar": "▲ 點擊上方更新頭像(寬高相同, 且小於300)",
    "account.uploadBanner": "▲ 點擊上方上傳圖片",
    "account.uploadDetailImage": "▲ 點擊上方選擇照片(2MB)",
    "account.uploadMachineBackground": "▲ 點擊上方上傳圖片(寬1080:高1920)",
    "account.constant.address": "戶籍地址",
    "account.constant.bank": "銀行名稱",
    "account.constant.bankAccount": "銀行帳號",
    "account.constant.bankBranch": "銀行分行",
    "account.constant.currentAddress": "通訊地址",
    "account.constant.foreign183less": "非本國籍且未待滿183天",
    "account.constant.foreign183more": "非本國籍且待滿183天",
    "account.constant.idNumber": "身分證字號",
    "account.constant.nationality": "國籍",
    "account.constant.native": "本國籍",
    "account.constant.phone": "聯絡電話",
    "account.constant.realName": "真實姓名",
    "account.changePlan": "變更方案",
    "account.currentPlan": "目前的方案為:",
    "account.plan": "實體機台合作方案",

    "albumMenu.albumNotCreated": "相簿未建立",
    "albumMenu.edit": "編輯此相簿",

    "banner.image": '橫幅相片 ({width}x{height})',
    "banner.cleanSelection": "清除選擇",
    "banner.error.extInValid": "檔案格式不支援",
    "banner.error.needTwoType": "兩種尺寸的橫幅照片都要上傳",
    "banner.error.overSize": "圖檔尺寸超過5MB，請重新上傳",
    "banner.error.sizeNotValid": "檔案的尺寸不符合規定",
    "banner.upload": "上傳橫幅",
    "banner.uploadHintP1": "點擊選擇圖檔(jpg或png, 5MB以內,",
    "banner.uploadHintP2": "圖檔的寬與高必須是",
    "banner.view": "檢視橫幅",

    "cashflow.amount": "金額",
    "cashflow.balance": "未支付收益",
    "cashflow.bank": "銀行帳戶",
    "cashflow.bankAccount": "帳戶後四碼",
    "cashflow.button.withdrawApplying": "出金申請中",
    "cashflow.date": "日期",
    "cashflow.error.cancel": "無法取消已完成的出金",
    "cashflow.error.withdrawApplied":
        "您的出金申請已提交, 請等待該筆申請完成後再進行",
    "cashflow.error.withdrawApply": "出金申請失敗，請稍後再試",
    "cashflow.error.withdrawNumber": "出金金額不能小於$1000或大於",
    "cashflow.expose": "總曝光數",
    "cashflow.fee": "手續費",
    "cashflow.hint.applyRequirement":
        "需收益超過$1000、信箱驗證以及填寫完整收款資料",
    "cashflow.hint.applying": "請等待處理完畢後再申請",
    "cashflow.info": "金流資訊",
    "cashflow.monthlyExpose": "本月總曝光數",
    "cashflow.monthlyRevenue": "本月收益",
    "cashflow.previousMonthExpose": "上月總曝光數",
    "cashflow.previousMonthRevenue": "上月收益",
    "cashflow.status": "狀態",
    "cashflow.success.withdrawApply": "出金申請成功！",
    "cashflow.summary": "成效",
    "cashflow.title": "Memopresso - 金流",
    "cashflow.withdrawAmount": "出金金額",
    "cashflow.withdrawApply": "出金申請",
    "cashflow.withdrawInfo":
        "出金金額最小值為$1000，\b出金過程中將扣除$30手續費，申請後將由接洽人員為您服務",
    "cashflow.withdrawRecord": "出金紀錄",

    "changePassword.changePassword": "變更密碼",
    "changePassword.error.password": "密碼錯誤",
    "changePassword.hint.inputNew": "輸入新密碼",
    "changePassword.hint.inputNewAgain": "重複輸入新密碼",
    "changePassword.hint.inputOld": "輸入舊密碼",
    "changePassword.success.change": "變更密碼完成",

    "frame.album.create": "建立相簿",
    "frame.manage": "管理相框",
    "frame.album.placeholder": "我的相框",
    "frame.error.notCensored": "您選擇的相框尚未完成審核",
    "frame.error.notFound": "沒有找到能夠更改的相框",
    "frame.error.sameStatus": "您選擇的相框已經是該狀態了",
    "frame.error.updateStatus": "更改狀態失敗",
    "frame.hint.createNewFrame": "點擊新增按鈕上傳新相框!",
    "frame.pageTitle": "Memopresso - 相框",
    "frame.success.updateStatus": "為您將相片更改狀態",
    "frame.selectBackFrame": "選擇相框底圖",
    "frame.selectLayer": "選擇上層相框",
    "frame.frameSetting": "相框設定",
    "frame.basicInfo": "基本資料",
    "frame.albumName": "相簿名稱",
    "frame.backFrame": "相框底圖",
    "frame.layerOptional": "上層相框(選填)",
    "frame.album.uploadLayerHintP1": `點擊選擇圖檔(png,
        5MB以內,`,
    "frame.finishedMessage":
        "成功新增相框, 目前新相框為未上線的狀態, 您可以等待工作人員審核完畢後將其上線",
    "frame.error.fileSize": "圖檔尺寸超過5MB，請重新上傳",
    "frame.error.sizeNotTheSame": "前後圖的尺寸不一致",
    "frame.error.emptyBackFrame": "未選擇相框底圖",
    "frame.error.merge": "合併後的相框出現問題, 請重新選擇相框後再試一次",
    "frame.error.album": "相簿出現問題，請稍後再嘗試或聯絡客服人員",
    "frame.albumEdit.title": "編輯相簿",
    "frame.album.background": "相簿圖檔(選填)",
    "frame.album.uploadHintP1": "點擊選擇圖檔(jpg或png, 5MB以內,",
    "frame.album.uploadHintP2": "檔案的寬與高必須是",
    "frame.hintAdImage": "列印時顯示的圖片(選填)",
    "frame.albumEdit.uploadAdHint": `點擊選擇檔案(jpg, png, gif, mp4,
        5MB以內,`,
    "frame.detail.title": "相框資訊",
    "frame.detail.preview": "預覽:",
    "frame.detail.backFrame": "相框底圖:",
    "frame.detail.layer": "上層相框:",
    "frame.album.filter": "篩選相框",
    "frame.album.moveTo": "移動至相簿",
    "frame.selectAll": "將此頁相框全選",
    "frame.downloadMultiple": "將已選相框下載",
    "frame.censorMultiple": "將已選相框過審",
    "frame.onlineMultiple": "將已選相框上線",
    "frame.offlineMultiple": "將已選相框下架",
    "frame.deleteMultiple": "將已選相框刪除",
    "frame.editTag": "編輯相框標籤",
    "frame.download": "下載此相框",
    "frame.censorOne": "將相框審核完畢",
    "frame.onlineOne": "將此相框上線",
    "frame.offlineOne": "將此相框下架",
    "frame.deleteOne": "將此相框刪除",
    "frame.add": "新增相框",
    "frame.showDemo": "顯示範例圖片",
    "frame.censorReject": "相框審核不通過",

    "faq.pageTitle": "Memopresso - 常見問題",
    "faq.header": "我們可以怎麼幫助您?",

    "tagEdit.title": "標籤編輯",
    "tag.multiple": "一個相框不能有相同的標籤",
    "tag.saveError": "儲存發生問題",
    "tagEdit.info.maxTagNumberP1": "最多可以加入",
    "tagEdit.info.maxTagNumberP2": "個標籤",
    "tagEdit.tagWithInP1": "(標籤",
    "tagEdit.tagWithInP2": "字內)",
    "tagEdit.error.maxTagNumberP1": "最多只能有",
    "tagEdit.error.maxTagNumberP2": "個標籤喔",
    "tagEdit.add": "加入標籤",

    "global.add": "新增",
    "global.album.add": "新增相簿",
    "global.album.manage": "管理相簿",
    "global.album.switch": "切換相簿",
    "global.album.edit": "編輯此相簿",
    "global.apply": "申請",
    "global.censor": "審核",
    "global.change": "更改",
    "global.create": "建立",
    "global.cleanSelection": "清除選擇",
    "global.delete": "刪除",
    "global.error.emailFormat": "信箱格式錯誤",
    "global.error.happened": "發生錯誤",
    "global.error.loadData": "讀取資料失敗，請稍後再試",
    "global.error.login": "登入出現問題，請重新登入後再嘗試或聯絡客服人員",
    "global.error.normal": "出現問題，請重新登入後再嘗試或聯絡客服人員",
    "global.error.notPermitted": "您沒有該操作的權限",
    "global.error.operate": "操作失敗，請稍後再試或聯絡客服人員",
    "global.error.passwordFormat": "密碼格式錯誤",
    "global.error.pwNotTheSame": "兩次輸入的密碼不相符",
    "global.error.selectFile": "選擇檔案失敗",
    "global.error.system": "系統發生錯誤，請稍後再試",
    "global.error.modify": "修改失敗",
    "global.hint.inputPassword": "輸入密碼",
    "global.hint.inputPasswordAgain": "請再次輸入密碼",
    "global.hint.passwordFormat": "密碼格式為6~20位英文數字組合",
    "global.noData": "無資料",
    "global.noTag": "無標籤",
    "global.offline": "下架",
    "global.online": "上線",
    "global.printed": "已列印",
    "global.reset": "重置",
    "global.select": "選取",
    "global.selectAll": "全選",
    "global.send": "送出",
    "global.success.delete": "刪除成功",
    "global.success.modify": "修改成功",
    "global.success.validate": "驗證成功",
    "global.unselectAll": "取消所有勾選",
    "global.upload": "上傳",
    "global.validate": "驗證",
    "global.cancelUpload": "取消上傳",
    "global.cancelUpload.warning":
        "您的上傳尚未完成, 如果您選擇關閉的話將會失去目前進度",
    "global.continueUpload": "繼續上傳",
    "global.cancel": "取消",
    "global.complete": "完成",
    "global.error": "錯誤",
    "global.refresh": "更新",
    "global.error.system.normal": "系統發生問題, 請稍後再試",
    "global.error.system.refresh": "系統發生問題,請重新整理頁面",
    "global.noValue": "無值",
    "global.processing": "處理中",
    "global.preview": "預覽",
    "global.update": "更新",
    "global.reselect": "重新選擇",
    "global.price": "價格",
    "global.priceDescription": "使用者看到的價格為",
    "global.error.imgSizeNotValid": "檔案的尺寸不符合規定",
    "global.error.extInValid": "檔案格式不支援",
    "global.close": "關閉",
    "global.back": "返回",
    "global.error.emptyPrice": "價格欄位不能是空的",
    "global.error.priceFormat": "價格只能是數字",
    "global.error.negativePrice": "價格不能是負數",
    "global.error.minimalPrice": "價格不能小於25",
    "global.cleanAll": "清除所有",
    "global.save": "儲存",
    "global.pixel": "像素",
    "global.error.specialSymbol": "名稱不能有特殊符號",
    "global.setting": "設定",
    "global.edit": "編輯",
    "global.next": "下一步",
    "global.error.add": "新增失敗，請稍後再試或聯絡客服人員",
    "global.success.add": "新增成功",
    "global.emptyResult": "沒有找到結果",
    "global.searching": "搜尋中",
    "albumswitch.hint.name": "輸入插畫家名稱",
    "global.deleteConfirmTitle": "確定要刪除嗎?",
    "global.deleteConfirm": "此項操作不能復原, 您確定要刪除嗎?",
    "global.confirm": "確定",
    "global.error.priceNotAMutiple": "價格只能為5的倍數",
    "global.desktop": "電腦版",
    "global.phone": "手機版",
    "global.input.empty": "請輸入{text}",
    "global.input.invalid": "請輸入正確{text}",
    "guide.pageTitle": "Memopresso - 上架方式",
    "guide.header": "上架方式",
    "guide.subHeader": "從註冊到上架販售！",
    "guide.step1Title": "創作者註冊",
    "guide.step1Text": "輸入Email並設定密碼，商家連結名稱請以英文設定。",
    "guide.step2Title": "創建相框相簿",
    "guide.step2Text1": "a.  進入後點選建立相簿即會跳出以下視窗",
    "guide.step2Text2":
        "b.  輸入相簿名稱。（此名稱將作為日後用戶搜尋您的關鍵字，後續無法更改）",
    "guide.step2Text3": "c.  上傳相簿圖檔。（可讓用戶更清楚找到您的商店）",
    "guide.step3Title": "上傳相框",
    "guide.step3Text1": "a.  按下“新增”即可上傳相框圖檔",
    "guide.step3Text2": "b.  依指示操作就完成囉！(單層相框上傳步驟一即可)",
    "guide.step4Title": "等待審核",
    "guide.step4Text": "上傳完成後，將針對是否符合審核準則進行審核。",
    "guide.step5Title": "商店開張！",
    "guide.step5Text1":
        "a. 當您看到相框狀態為“未上線”，表示已通過審核，後續您可自行調整成“上線中”",
    "guide.step5Text2": "b. 可新增標籤增加搜尋時的曝光度。",
    "guide.step6Title": "提領收入",
    "guide.step6Text1": "收入將依照您的定價進行分潤。",
    "guide.coop.title": "分潤合作方案",
    "guide.comission.online.title": "線上商店分潤",
    "guide.step6Text2": "＊定價分潤計算方式：",
    "guide.comissionOnlineText1": "兩張50元至100元：提供您20%（含稅）拆潤",
    "guide.comissionOnlineText2": "兩張125元至300元：提供您30%（含稅）拆潤",
    "guide.comissionOnlineText3": "兩張325元至450元：提供您40%（含稅）拆潤",
    "guide.comissionOnlineText4": "兩張475元（含）以上：提供您50%（含稅）拆潤",
    "guide.comissionOnlineText5": "＊為避免價格過於繁雜，定價需以25元為單位",
    "guide.comissionOnlineText6":
        "＊此分潤僅適用線上商店之銷售額，因定價彈性，實體機台尚未配合",
    "guide.comission.offline.title": "實體機台合作方案",
    "guide.comissionOfflineText1": "以下方案三選一：",
    "guide.comissionOfflineText2":
        "（方案為期三個月，欲更改方案可在三個月後於creators studio 更改）",
    "guide.comissionOfflineText3":
        "Ａ：每個創作相框提供額外10組序號讓您與粉絲互動",
    "guide.comissionOfflineText4":
        "Ｂ：機台首頁、等待列印頁面行銷曝光（各一張）",
    "guide.comissionOfflineText5": "Ｃ：機台銷售額提供5%（含稅）拆潤",
    "guide.comissionOfflineText6": "＊實體機台販售價格均為兩張50元",
    "guide.comission.comment":
        "您可以獲得線上商店分潤外加一種實體機台合作方案。",
    "guide.step3step1": "Step.1 選擇底圖",
    "guide.step3step2": "Step.2 選擇上層",
    "guide.step3step3": "Step.3 預覽",
    "guide.step3step3Optional": "(可開啟顯示範例圖片檢查設計是否被遮擋)",
    "guide.step3step4": "Step.4 定價",
    "guide.step3step4Optional": "＊50元之倍數(2張)才可於機台使用現金付款哦！",
    "guide.step3step5": "Step.5 完成！等待審核",
    "guide.step5Comment":
        "＊ 新上架相框均提供一週的時間曝光於實體機台，一週後是否繼續曝光將由演算法依銷售成效判定。",

    "home.pageTitle": "Memopresso Creator Market",
    "home.introTitleLine1": "設計你獨一無二的相框",
    "home.introTitleLine2": "保存你所有美好的回憶！",
    "home.joinButton": "立即開始",
    "home.point1.title": "免費上架，讓您盡情揮灑創意。",
    "home.point1.description": "免註冊費、免上架費，人人都可以是創作者！",
    "home.point1.link": "逛逛插畫家相框市集",
    "home.point2.title": "彈性定價，自由販售您設計的相框。",
    "home.point2.description": `相框依照不同販售價格可獲得不同的分潤比例，自由尋找最合適的價格吧！`,
    "home.point2.link": "了解詳細定價與分潤方式",
    "home.point3.title": "後台輕鬆查看相框成效，隨時追蹤數據。",
    "home.point3.description": "隨時可以查看您的曝光率與收益，資訊不漏接！",
    "home.point3.link": "註冊我的商店",
    "home.point4.title":
        "讓朋友、另一半都來使用您的相框保存彼此之間的重要回憶吧！",
    "home.point4.description": "了解設計規範讓您節省時間快速上架！",
    "home.point4.link": "查看相框製作規範",
    "home.bottomTitleLine1": "不知從哪著手嗎？別擔心，",
    "home.bottomTitleLine2": "先來逛逛超過百位插畫家的相框市集吧！",
    "home.goMarket": "相框市集GO!",

    "image.album.placeHolder": "我的相片",
    "image.edit.height": "目前高度像素:",
    "image.edit.minimal": " (最低",
    "image.edit.rotateRight90": "向右轉90度",
    "image.edit.toHorizontal": "切換成橫向",
    "image.edit.toVertical": "切換成直向",
    "image.edit.width": "目前寬度像素:",
    "image.edit.zoom": "縮放",
    "image.error.notCensored": "需要等待相片審核完後才能進行該操作",
    "image.error.notFound": "沒有找到目前可更改狀態的相片",
    "image.error.sameStatus": "該相片已經",
    "image.error.updateStatus": "更改狀態失敗",
    "image.hint.createNewImage": "點擊新增按鈕上傳新相片!",
    "image.pageTitle": "Memopresso - 相片",
    "image.success.updateStatus": "為您將已審核完畢的相片更改狀態",

    "image.select.image": "選擇相片",
    "image.select.video": "選擇影片(選填)",
    "image.fillData": "填寫資料",
    "image.video.calculating": "選填 - 影片設定(請稍後...計算信心度中)",
    "image.video.lowConfidence":
        "選填 - 影片設定(很抱歉, 這張照片可能會無法被辨識, 請更換一張)",
    "image.video.setting": "選填 - 影片設定",
    "image.error.sizeOver": "照片尺寸超過10MB，請重新上傳",
    "image.error.extInValid": "照片格式目前僅支援jpg",
    "image.error.sizeInValid": "相片最短的邊的長度需要大於",
    "image.info.autoAdjustSize":
        "已自動為您調整照片的長寬, 如果想要處理高解析度的照片, 請使用電腦版的網頁來上傳",
    "image.error.select": "選擇相片失敗",
    "image.error.videoOverSize": "影片尺寸超過20MB，請重新上傳",
    "image.error.videoExtInValid": "目前不支援這個影片的格式",
    "image.error.uploadVideo": "上傳影片失敗",
    "image.error.emptyName": "相片名稱不能是空白的",
    "image.error.emptyImage": "相片不能為空",
    "image.error.lowConfidenceUpload":
        "很抱歉, 這張照片可能會無法被辨識, 請更換一張",
    "image.error.videoExtNotSupport": "目前不支援這個影片的格式",
    "image.imageNotSelected": "您必須先選擇相片才能進行下一步",
    "image.error.videoLength": "影片不能超過30秒",
    "image.error.videoRatio":
        "為了不影響到影片比例, 您需要選擇與相片長寬比相似的影片",
    "image.add": "新增相片",
    "image.image": "相片",
    "image.hint.upload": `點擊選擇照片(jpg,
        10MB以內)`,
    "image.hint.cropDone": "完成裁切",
    "image.hint.reCrop": "重新裁切",
    "image.detail": "相片資訊",
    "image.detail.name": "相片名稱:",
    "image.detail.price": "相片價格:",
    "image.detail.status": "相片狀態:",
    "global.contactCustomService": "(如有問題, 請聯繫粉專小編)",
    "image.detail.clickToPreview": "(點擊預覽)",
    "image.detail.arDeviceHint": `(Iphone使用者請用Safari開啟,
        Android使用者請用Chrome開啟)`,
    "image.detail.uploadTime": "上傳時間:",
    "image.detail.videoPreview": "影片預覽",

    "image.expand.filter": "篩選相片",
    "image.expand.moveTo": "移動至相簿",

    "image.selectAll": "將此頁相片全選",
    "image.downloadMultiple": "將已選相片下載",
    "image.censorMultiple": "將已選相片過審",
    "image.onlineMultiple": "將已選相片上線",
    "image.offlineMultiple": "將已選相片下架",
    "image.deleteMultiple": "將已選相片刪除",
    "image.editTag": "編輯相片標籤",
    "image.download": "下載此相片",
    "image.censorOne": "將相片審核完畢",
    "image.onlineOne": "將此相片上線",
    "image.offlineOne": "將此相片下架",
    "image.deleteOne": "將此相片刪除",
    "image.hint.selectVideo": `點擊選擇30秒內的影片(影片長寬比須與照片類似,
        20MB以內)`,
    "image.name": `相片名稱`,
    "image.success.add": "成功新增照片!",
    "image.finishedMessage":
        "準備影片需要大約10分鐘左右的時間, 您可以關閉這個視窗做其他的事情. 之後您可以點擊照片打開詳細頁面查看是否已完成.",

    "login.error": "帳號或密碼錯誤",
    "login.error.emptyEmail": "請輸入Email",
    "login.error.password": "請輸入密碼",
    "login.forgetPassword": "忘記密碼？",
    "login.forgotPasswordMessage": "\b系統將會寄出更改密碼連結至目標信箱",
    "login.forgotPasswordTitle": "忘記密碼",
    "login.register": "沒有帳號嗎? 點此免費註冊",
    "login.serviceTerm": "Memopresso 服務條款",
    "login.success": "登入成功",
    "login.success.resetLinkSent": "重置密碼連結已送出",

    "manage.cashflow.amount": "金額",
    "manage.cashflow.applyDate": "申請日期",
    "manage.cashflow.balance": "帳戶餘額",
    "manage.cashflow.bank": "銀行帳戶",
    "manage.cashflow.bankAccount": "帳戶",
    "manage.cashflow.completeTime": "完成日期",
    "manage.cashflow.error.update": "發生錯誤, 請先檢查餘額是否足夠",
    "manage.cashflow.fee": "手續費",
    "manage.cashflow.status": "狀態",
    "manage.cashflow.title": "請款處理",
    "manage.delivery.address": "地址",
    "manage.delivery.copyAddress": "已複製地址",
    "manage.delivery.copyAddressHint": "點擊複製地址",
    "manage.delivery.copyRecipient": "已複製收件人",
    "manage.delivery.copyRecipientHint": "點擊複製收件人",
    "manage.delivery.createDate": "時間",
    "manage.delivery.deliveryTime": "寄出時間",
    "manage.delivery.mailingNumber": "郵寄編號",
    "manage.delivery.mobile": "手機",
    "manage.delivery.orderId": "訂單編號",
    "manage.delivery.payment": "付款方式",
    "manage.delivery.price": "金額",
    "manage.delivery.quantity": "張數",
    "manage.delivery.recipient": "收件人",
    "manage.delivery.serialcode": "列印序號",
    "manage.delivery.shippingfee": "運費",
    "manage.delivery.status": "訂單狀態",
    "manage.delivery.remarks": "備註",
    "manage.delivery.title": "寄件資訊",
    "manage.delivery.user": "會員編號",
    "manage.delivery.select": "已選擇 {0} 筆",
    "manage.delivery.download.xslx": "下載為 xslx",
    "manage.delivery.download.pdf": "下載為 PDF",
    "manage.coupon.name": "活動名稱",
    "manage.coupon.newCustomer": "新用戶",
    "manage.coupon.tags": "活動標籤",
    "manage.coupon.quantity": "數量",
    "manage.coupon.count": "已使用",
    "manage.coupon.uselimit": "使用上限",
    "manage.coupon.channel": "通路",
    "manage.coupon.startDate": "開始時間",
    "manage.coupon.endDate": "截止時間",
    "manage.coupon.type": "類型",
    "manage.coupon.discount": "折扣/回饋",
    "manage.coupon.minimum": "滿額條件",
    "manage.coupon.maximum": "折扣上限",
    "manage.coupon.serialNumber": "序號",
    "manage.coupon.creator": "創建者",
    "manage.coupon.updatedTime": "修改日期",
    "manage.coupon.title": "優惠券管理",
    "manage.coupon.success.add": "優惠券新增成功",
    "manage.coupon.error.add": "優惠券新增失敗",
    "manage.coupon.success.update": "優惠券修改成功",
    "manage.coupon.error.update": "優惠券修改失敗",
    "manage.homepage.banner.eventName": "活動名稱",
    "manage.homepage.banner.eventDate": "上架時間",
    "manage.hompage.banner.image": '{size}橫幅({width}x{height})',
    "manage.hompage.banner.del.confirm": "確定刪除 ?",

    'manage.hompage.category.eventName.duplicate':'活動名稱重複',
    "manage.hompage.category.update": "更新主題",
    "manage.hompage.category.frame.weekHot": '本週熱門',
    "manage.hompage.category.frame.monthHot": '本月熱門',
    "manage.hompage.category.frame.recommend": '推薦相框',
    "manage.hompage.category.frame.new": '新進創作',
    "manage.hompage.category.frame.other": '其他',
    "manage.hompage.category.product.mask": '口罩收納盒',
    "manage.hompage.category.product.frame": '相框',
    "manage.hompage.category.zh": '中文',
    "manage.hompage.category.en": '英文',
    "manage.hompage.category.th": '泰文',
    "manage.hompage.category.ko": '韓文',
    "manage.hompage.category.image": '主題相片({width}x{height})',
    "manage.hompage.category.tag": '相框標籤',
    "manage.hompage.category.tag.notfound": '找不到標籤',
    "manage.hompage.category.tag.invalid": '請輸入有效標籤',
    "manage.hompage.category.tag.duplicate": "主題標重複",

    "manual.pageTitle": "Memopresso - 設計規範",
    "manual.faceFrame": "人像相框",
    "manual.imageFrame": "圖像相框",
    "manual.requiredAmount": "所需數量",
    "manual.requiredPixel": "圖片大小(Pixel)",
    "manual.frameDesignLayer": "* 相框設計層",
    "manual.albumFile": "相簿圖檔",
    "manual.machineHomePage": "機台首頁設計圖",
    "manual.waitingPrintPage": "等待列印頁面",
    "manual.header": "設計規範",
    "manual.subHeader": "您需要準備…",
    "manual.frameTableHeader": "相框檔案",
    "manual.machineTableHeader": "機台合作檔案",
    "manual.comment1": "·　有＊符號的為必要準備項目",
    "manual.comment2": "相簿圖檔：",
    "manual.comment3":
        "可想像成作品集封面，用戶在選相框時，會先看到相簿圖，裡面才是您的所有相框。",
    "manual.comment4": "機台首頁設計圖：",
    "manual.comment5": "設計圖會於全台實體機台首頁輪播曝光。",
    "manual.comment6": "等待列印頁面：",
    "manual.comment7": "等待相片列印時約需15~30秒，用戶等待列印時可再次曝光。",
    "manual.frameExampleHeader": "相框範例",

    "manual.frameDesignLayerNumber": "單層1張或雙層2張",
    "manual.frameDesignConcept": "相框設計概念",
    "manual.frameSingleDesignConcept": "單層設計：底色層 x 1",
    "manual.frameDoubleDesignConcept": "雙層設計：設計層x 1、底色層 x 1",
    "manual.singleLayer": "單層相框",
    "manual.doubleLayer": "雙層相框",
    "manual.albumScreen": "相簿圖檔呈現畫面",
    "manual.printingPage": "等待列印廣告頁面",
    "manual.machineHomePageTitle": "機台首頁",
    "manual.preserveSpace": "預留邊界",
    "manual.frameSpecLine1": "含出血相框大小：897 x 1205 px",
    "manual.frameSpecLine2": "完稿尺寸（紅線內）：881 x 1177px",
    "manual.frameSpecLine3": "左右上方各出血 8px",
    "manual.frameSpecLine4": "下方出血 20px",
    "manual.frameDesignExampleHeader": "設計題材參考",
    "manual.celebrationDayFrame": "節日相框",
    "manual.themeFrame": "主題相框",
    "manual.aiDownload": "尺寸ai檔下載",
    "manual.frameAndAlbumAI": "相框＆相簿圖ai檔",
    "manual.machineHomePageAndPrintPageAI": "機台首頁設計＆列印頁面ai檔",
    "manual.designRule": "違規條例",
    "manual.designRuleTitle": "若相框含有以下內容，",
    "manual.designRuleTitleEmphasis": "審核將不予通過",
    "manual.designRule1":
        "違反善良風俗、教唆未成年者飲酒吸菸、猥褻、暴力、煽動民族主義的內容",
    "manual.designRulecLine2":
        "上傳過於裸露、煽情、挑逗之個人照片，包含以馬賽克或塗白等方式遮掩重要部位皆屬違規",
    "manual.designRulecLine3": "上傳明星、其他KOL等非本人之照片相框",
    "manual.designRulecLine4": "抄襲、盜用其他插畫家之設計相框",
    "manual.designRulecLine5":
        "過度商業化之相框內容，如：於相框、名稱內置入公告或企業商標",
    "manual.face.comment6": "·　目前人像相框不支援實體機台上架。",
    "manual.face.comment4": "人像相框：",
    "manual.face.comment5":
        "以照片攝影方式所呈現出的人物肖像，若是經過繪圖、顏繪、Ｑ版畫風等以插畫方式呈現者則可歸類於圖像相框。",
    "manual.tutorialTitle": "沒想法嗎？這裡提供懶人包讓您快速上手！",
    "manual.threeStepTutorial": "超簡單三步驟相框教學",
    "manual.albumScreenMobile": "在手機上",
    "manual.albumScreenMachine": "在機台上",

    "navigation.account": "帳戶資訊",
    "navigation.admin": "後臺管理",
    "navigation.cashflow": "金流",
    "navigation.cashflowManage": "請款處理",
    "navigation.dashboard": "登入",
    "navigation.delivery": "訂單運送",
    "navigation.faq": "常見問題",
    "navigation.frame": "相框",
    "navigation.market": "市集",
    "navigation.manual": "設計規範",
    "navigation.guide": "上架方式",
    "navigation.home": "主頁面",
    "navigation.image": "相片",
    "navigation.login": "登入",
    "navigation.logout": "登出",
    "navigation.logoutFailed": "登出失敗",
    "navigation.logoutSucceed": "登出成功",
    "navigation.frameManage": "相框管理",
    "navigation.coupon": "優惠券管理",
    "navigation.homepage": '首頁管理',
    "product.status.censoring": "審核中",
    "product.status.deleted": "排定刪除",
    "product.status.none": "無",
    "product.status.offline": "未上線",
    "product.status.online": "上線中",
    "product.status.processing": "處理中",
    "product.status.ready": "就緒",
    "product.status.waiting": "等待處理中",
    "product.status.rejected": "未通過",

    "plan.accordion.header": "1 + 1 分潤合作方案詳情",

    "register.agreeTerm": "註冊表示同意",
    "register.alreadyHasAccount": "已經擁有帳號了嗎？",
    "register.error.emailUsed": "信箱已被使用",
    "register.error.linkNameFormat": "商家連結名稱格式錯誤",
    "register.goLogin": "直接登入",
    "register.help.linkName": "商家連結名稱格式為6~20位英文數字組合",
    "register.hint.linkName": "商家連結名稱",
    "register.register": "註冊",
    "register.success.login": "註冊成功，稍後將帶您回登入頁面",
    "register.term": "服務條款",
    "register.title": "建立帳戶",

    "reset.goBackToLogin": "回到登入頁面",
    "reset.linkOutDated": "連結失效",
    "reset.pageTitle": "Memopresso - 密碼重置",
    "reset.password.reset": "密碼重置",
    "reset.success.updatePW": "變更密碼完成",

    "updateAccount.brandName": "商家名稱",
    "updateAccount.description": "描述",
    "updateAccount.error.empty": "欄位不能為空",
    "updateAccount.error.linkFormat": "商家連結名稱格式錯誤",
    "updateAccount.error.linkUsed": "商家連結名稱已被使用",
    "updateAccount.error.update": "變更資訊失敗",
    "updateAccount.fbUrl": "Facebook網址",
    "updateAccount.igUrl": "Instagram網址",
    "updateAccount.linkName": "商家連結名稱",
    "updateAccount.linkNameEn": "商家連結名稱(英文)",
    "updateAccount.success.update": "變更資訊成功",
    "updateAccount.update": "變更資訊",
    "updateDetail.error.empty": "請填寫目前空白的欄位",
    "updateDetail.error.update": "變更資訊失敗",
    "updateDetail.hint.address": "某某縣市某某區某某路某號",
    "updateDetail.hint.bank": "點選開戶的銀行",
    "updateDetail.hint.bankBranch": "點選開戶的銀行分行",
    "updateDetail.hint.name": "王小明",
    "updateDetail.hint.nationality": "國籍",
    "updateDetail.hint.same": "同上",
    "updateDetail.success.update": "變更收款資訊成功",
    "updateDetail.update": "變更收款資訊",
    "updateStory.error.update": "變更網址失敗",
    "updateStory.error.urlFormat": "網址格式錯誤",
    "updateStory.hint.url": "Story網址",
    "updateStory.success.update": "變更網址成功",
    "updateStory.update": "變更Story網址",

    "verifyEmail.email": "驗證信箱",
    "verifyEmail.emailResent": "已重新寄送",
    "verifyEmail.error.number": "驗證碼錯誤",
    "verifyEmail.error.numberFormat": "驗證碼需為六位數字",
    "verifyEmail.hint.emailNotReceived": "驗證信已寄出，沒有收到嗎？",
    "verifyEmail.hint.number": "輸入六位數字",
    "verifyEmail.sendAgain": "再寄一次",
    "verifyEmail.success": "驗證信箱成功",

    "notfound.pageNotFound": "抱歉, 沒有找到頁面",

    "faq.faq": "常見問題",

    // Footer
    "footer.about": "關於 Memopresso",
    "footer.intro":
        "透過個性化社群軟體與軟硬整合，無人零售的技術，插畫家、創作者合作，提供年輕一代使用者快速保存，製作與分享個性化相片的解決方案，打造“有感社交” 的社群分享體驗。",
    "footer.guideTitle": "連結",
    "footer.guide.about": "關於",
    "footer.guide.use": "使用說明",
    "footer.guide.location": "機台地點",
    "footer.guide.hotCreator": "熱門創作者",
    "footer.guide.business": "商業合作",
    "footer.guide.lang": "語言",
    "footer.contact.tel": "客服電話: 02-37655060",
    "footer.contact.mail": "客服信箱: business@memopresso.com",
    "footer.guide.link": "創作者專區",
    "footer.guide.termAndPolicy": "服務條款與隱私權政策",
    "footer.guide.policy": "隱私權政策",
    "footer.guide.censor": "審核準則說明",

    "searchbox.placeholder": "請輸入您的問題"
};

export default zh;

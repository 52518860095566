const Language = {
    EN: "en",
    TW: "zh-Hant-HK",
};

const Sites = {
    Dashboard: "dashboard",
    Market: "market",
    NotFound: "notFound",
};

const DashboardPaths = {
    login: "/login",
    cashflow: "/cashflow",
    account: "/account",
    frames: "/frames",
    frameManage: "/frame-manage",
    images: "/images",
    delivery: "/manage/delivery",
    cashflowManage: "/manage/cashflow-manage",
    manage: "/manage",
    accountManage: "/manage/account",
    coupon: "/manage/coupon",
    homepage: "/manage/homepage",
};

const MarketPaths = {
    home: "/",
    manual: "/manual",
    guide: "/guide",
    faq: "/faq",
    dashboard: "/login",
};

const ChartColor = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
];
const BorderColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
];

export {
    Language,
    Sites,
    DashboardPaths,
    MarketPaths,
    ChartColor,
    BorderColor,
};

import ReactGA from "react-ga";

export const Category = {
    System: "系統",
    Navigation: "瀏覽",
    Rules: "條款",
    SocialMedia: "社交平台",
    Manual: "手冊",
    FAQ: "常見問題",
    Cashflow: "金流",
    Account: "帳戶資訊",
    Frame: "相框"
};

export const Action = {
    // System
    Login: "登入",
    Logout: "登出",
    Register: "註冊",
    ForgotPassword: "忘記密碼",
    SendResetLink: "送出重新設定連結",
    SendResetLinkCancel: "取消送出重新設定連結",
    VerifyEmail: "驗證信箱",
    ResendVerificationCode: "重新寄出驗證信",
    VerifyEmailDialogClose: "關閉驗證信箱視窗",
    //Login

    //Navigation
    RedirectToManual: "前往 設計規範",
    RedirectToCreatorMarket: "前往 市集",
    RedirectToGuide: "前往 上架方式",
    RedirectToDashboard: "前往 Dashboard",
    RedirectToHome: "前往 主頁",
    RedirectToTutorial: "前往 教學",
    RedirectToFAQ: "前往 常見問題",
    DrawerRedirect: "Drawer Redirects",

    //Rules
    TermsAndService: "服務條款與隱私權政策",
    Censoring: "審核準則說明",
    TermsDialogAccept: "服務條款與隱私權政策 確認",
    CensoringDialogAccept: "審核準則說明 確認",

    //Social Media
    MPFacebook: "Memopresso Facebook",
    MPInstagram: "Memopresso Instagram",
    MPLine: "Memopresso Line",

    //Manual
    ImageFrame: "圖像相框",
    FaceFrame: "人像相框",

    //ImageFrame Manual
    DesignSample: "相框範例",
    FrameAlbumAI: "相框＆相簿圖ai檔",
    MachineHomeDesign: "機台首頁設計＆列印頁面ai檔",

    //FaceFrame Manual
    FrameTutorial: "相框教學",

    //FAQ
    FAQCategory: "常見問題 分類",
    FAQQuestion: "常見問題 問題",
    FAQMoreQuestion: "常見問題 查看更多",

    //金流
    CashflowInfo: "金流資訊",
    CashflowHistory: "出金紀錄",
    CashflowWithdrawApply: "出金申請",
    PlanAccordionOpen: "打開方案項目",
    PlanAccordionClose: "關閉方案項目",
    PlanSelectionDialog: "選擇方案視窗",
    PlanSelection: "方案選擇",
    PlanSelectionSecondStep: "方案選擇 第二步驟",
    PlanSelectionClose: "方案選擇 關閉",
    PlanSelectionSubmit: "方案選擇 送出",
    PlanSelectionConfirmSubmit: "確認送出",
    PlanSelectionConfirmClose: "確認送出 關閉",
    PlanSelectionFinish: "方案選擇 結束",

    //Account
    ChangePasswordDialog: "變更密碼 視窗",
    ChangePasswordDialogClose: "變更密碼 關閉",
    ChangePasswordConfirm: "變更密碼 確認",
    AvatarImageClicksed: "頭像 點擊",
    CreatorLink: "商家連結",
    CreatorFacebook: "商家 Facebook",
    CreatorInstagram: "商家 Instagram",
    UpdateAccountDialog: "變更資訊 視窗",
    UpdateAccountDialogClose: "變更資訊 關閉",
    UpdateAccountConfirm: "變更資訊 確認",

    BannerImageSelectDialog: "橫幅圖片 選擇視窗",
    BannerImageSelectLarge: "橫幅圖片 大",
    BannerImageSelectLargeReset: "橫幅圖片 大 清除",
    BannerImageSelectSmall: "橫幅圖片 小",
    BannerImageSelectSmallReset: "橫幅圖片 小 清除",
    BannerImageSelectCancel: "橫幅圖片 選擇取消",
    BannerImageUpload: "橫幅圖片 上傳",

    BannerImageShow: "橫幅圖片 檢視",
    BannerImageRemoveCancel: "刪除橫橫幅圖片 取消",
    BannerImageRemoveConfirm: "刪除橫橫幅圖片 確認",

    MachineBackgroundSelect: "機器背景圖 選擇",
    MachineBackgroundUpload: "機器背景圖 上傳",
    MachineBackgroundUploadCancel: "機器背景圖 取消上傳",
    MachineBackgroundPreview: "機器背景圖 預覽",
    MachineBackgroundRemove: "機器背景圖 刪除",

    AddDetailDialog: "新增收款資訊",
    UpdateDetailDialog: "變更收款資訊",
    UpdateDetailDialogClose: "變更收款資訊 關閉",
    UpdateDetailDone: "變更收款資訊 完成",

    FrontIdSelect: "身分證正面照片 選擇",
    FrontBackSelect: "身分證反面照片 選擇",
    PassbookSelect: "存摺封面照片 選擇",

    //Frame
    CreateAlbumDialog: "建立相簿 視窗",
    CreateAlbumDialogClose: "建立相簿 視窗關閉",
    CreateAlbum: "建立相簿",
    AlbumCoverSelect: "相簿圖檔 選擇",
    AlbumCoverReSelect: "相簿圖檔 重新選擇",
    PrintedImageViewSelect: "列印時顯示的圖片 選擇",
    PrintedImageViewReselect: "列印時顯示的圖片 重新選擇",
    ManageAlbum: "管理相框",
    /* 新增相框 */
    CreateNewFrameDialog: "新增相框 視窗",
    CreateNewFrameCancelDialog: "新增相框 取消視窗",
    CreateNewFramePreviousStep: "新增相框 返回",
    CreateNewFrameNextStep: "新增相框 下一步",
    BackFrameUpload: "相框底圖 上傳",
    BackFrameReupload: "相框底圖 重新上傳",
    FrontFrameUpload: "相框上層 上傳",
    FrontFrameReupload: "相框上層 重新上傳",
    FrameDemoOptionChanged: "範例圖片 更改",
    FramePriceChanged: "相框價格設定 改變",
    FrameUploadConfirmDialog: "上傳相框 視窗",
    FrameUploadConfirm: "上傳相框 確認",
    FrameUploadConfirmDialogClose: "上傳相框 視窗關閉",
    FrameUploadFinished: "上傳相框 結束",
    /* Group operation */
    ManageAlbumGroupOperationClicked: "點擊 管理相簿",
    CancelAlbumGroupOperationSelection: "取消 管理相簿",
    ManageAlbumSelectAllFrame: "選擇所有相框",
    ManageAlbumUnselectAllFrame: "取消選擇所有相框",
    ManageAlbumDeleteFrame: "刪除已選相框",
    ManageAlbumSetStatusOffline: "下架已選相框",
    ManageAlbumSetStatusOnline: "上架已選相框",
    /* 編輯相簿 */
    EditAlbumDialog: "編輯相簿 視窗",
    EditAlbumDialogClose: "編輯相簿 視窗關閉",
    EditAlbumUpdate: "編輯相簿 更新",
    EditAlbumLogo: "編輯相簿 相s簿圖檔",
    EditAlbumAd: "編輯相簿 列印時顯示的圖片",
    EditAlbumClearLogo: "編輯相簿 清除 相簿圖檔",
    EditAlbumClearAd: "編輯相簿 清除 列印時顯示的圖片",
    /*單項相框*/
    SingleFrameSetStatusOnline: "單項相框 上架",
    SingleFrameSetStatusOffline: "單項相框 下架",
    SingleFrameSelect: "單項相框 選取",
    SingleFrameDetail: "單項相框 資訊",
    SingleFrameTagEdit: "標籤編輯"
};

export const GAInit = trackingID => {
    ReactGA.initialize(trackingID);
};

export const GAPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
export const GAEvent = (category, action, label, value) => {
    ReactGA.event({
        category,
        action,
        label: `${label}`,
        value
    });
};

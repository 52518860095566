import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "./theme/MuiTheme.js";

/* Global Style */
import "./assets/css/index.css";

// Redux
import { Provider } from "react-redux"; /* react bindings for redux */
import Store, { history } from "store/store";
import { ConnectedRouter } from "connected-react-router";

import App from "./components/app/App";
import { setRegion } from "./actions/general";
import ConnectedIntlProvider from "./components/intlProvider/IntlProvider";
const store = Store(/* provide initial state if any */);
store.dispatch(setRegion()); //取得使用者地區
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ConnectedIntlProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </ConnectedIntlProvider>
        </ConnectedRouter>
    </Provider >,
    document.getElementById("root")
);

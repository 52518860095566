import AccountAPI from "services/AccountAPI";

export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const LOGOUT = "LOGOUT";

const func = () => {};
/**
 * 獲取使用者資訊
 * @param callback - (data, error)
 * @return {function(*)} - action
 */
export const fetchAccount = (callback = func) => async (dispatch) => {
	try {
		const data = await AccountAPI.Fetch();
		callback(data, null);

		return dispatch({
			type: FETCH_ACCOUNT,
			payload: data,
		});
	} catch (error) {
		callback({}, error);
	}
};

/**
 * 登出
 * @param callback - (data, error)
 * @return {function(*)} - action
 */
export const logout = (callback = func) => async (dispatch) => {
	try {
		await AccountAPI.Logout();
		callback({}, null);
		return dispatch({
			type: LOGOUT,
			payload: {},
		});
	} catch (error) {
		callback({}, error);
	}
};

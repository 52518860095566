const ENV = {
    isDev:
        process.env.NODE_ENV !== "production" ||
        window.origin.includes("memopresso"),
    isProd: process.env.NODE_ENV === "production",
};

const Config = {
    BASE_URL:
        process.env.NODE_ENV !== "production"
            ? "http://localhost:3000"
            : `${window.location.origin}/api`,
    NFT_URL:
        process.env.NODE_ENV !== "production"
            ? "http://localhost:5000"
            : `https://ar.memopresso.com/api/node/nft`,
    AR_URL:
        process.env.NODE_ENV !== "production"
            ? "http://localhost:3001/image"
            : `https://ar.memopresso.com/image`,
    GEO_LOCATION_URL: "https://asia-east2-analog-provider-226605.cloudfunctions.net/geo-location",
    ACCEPT_LANG: ["en", "zh-tw"],
};

export { ENV };
export default Config;
